import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ImageCard } from "./imageCard";
import axios from "axios";
import { Close } from "@mui/icons-material";

export const First = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(true);
  const [ans, setAns] = useState("");

  const [answer, setAnswer] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false); // Закрыть диалог
  };

  useEffect(() => {
    if (ans) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [ans]);

  return (
    <Box>
      <Typography sx={{ fontSize: "20px" }}>{props.taskText}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column", xl: "row" },
          height: "100%",
          gap: { xs: 2, sm: 2, xl: 4 },
        }}
      >
        <FormControl sx={{ mt: { sm: 0, xl: 4 } }}>
          <RadioGroup>
            {props.ans.map((value, index) => (
              <FormControlLabel
                onClick={() => {
                  setAns(index + 1);
                }}
                key={index}
                labelPlacement="end"
                value={value}
                control={
                  <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />
                }
                label={<span style={{ fontSize: "20px" }}>{value}</span>}
              />
            ))}
          </RadioGroup>
          <Collapse in={modalOpen}>
            <Alert
              severity={
                modalData ===
                "Серверные шоколадки, попробуйте еще раз отправить ответ"
                  ? "error"
                  : "success"
              }
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {modalData}
              <br />
            </Alert>
          </Collapse>
          <Button
            disabled={!isDisabled}
            variant="contained"
            color="secondary"
            sx={{ mt: 4, mb: 4 }}
            onClick={async () => {
              try {
                const requestBody = {
                  task_id: props.id,
                  answer: ans + "",
                };
                const response = await axios.post(
                  "/api/tasks/check-task",
                  requestBody
                );
                setModalData(response.data.message);
                setModalOpen(true);
                if (props.id === 8 || props.id === 14) {
                  setOpen(true); // Открыть диалог
                  try {
                    const response = await axios.get("/api/Correct-answers", {
                      params: {
                        task_id: props.id,
                      },
                    });
                    setAnswer(response.data.status);
                  } catch (error) {
                    setAnswer("Серверные шоколадки, попробуйте еще раз");
                  }
                }
              } catch (error) {
                setModalData(
                  "Серверные шоколадки, попробуйте еще раз отправить ответ"
                );
                setModalOpen(true);
              }
            }}
          >
            Отправить ответ
          </Button>
        </FormControl>
        <ImageCard
          taskTheme={props.taskTheme}
          taskFact={props.taskFact}
          imgAdress={props.imgAdress}
          gg={props.gg}
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              fontSize: "24px",
              textWrap: "wrap",
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography variant="h4">Ответ на задание: </Typography>
            <Typography variant="h5">{answer}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
