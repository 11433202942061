import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Close } from "@mui/icons-material"; // Иконка ZoomIn для лупы

export const ImageCard = (props) => {
  const [zoomOpen, setZoomOpen] = useState(false);

  const handleZoomOpen = () => {
    setZoomOpen(true);
  };

  const handleZoomClose = () => {
    setZoomOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: 500,
          mt: { sm: 1, xl: 4 },
          alignSelf: { sm: "center", xl: "start" },
          mb: 2,
          width: "100%",
        }}
        /*variant="outlined" */
      >
        <CardActionArea onClick={handleZoomOpen}>
          <CardMedia component="img" height="240" image={props.imgAdress} />
          {props.tt !== 2 ? (
            <>
              <CardContent sx={{ display: props.gg === 6 ? "none" : "block" }}>
                <Typography gutterBottom variant="h5" component="div">
                  {props.taskTheme}
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  {props.taskFact}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifySelf: "center", display: "none" }}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleZoomOpen}
                >
                  Приблизить картинку
                </Button>
              </CardActions>
            </>
          ) : (
            <CardContent>
              <CardActions
                sx={{
                  justifySelf: "center",
                  display: "block",
                }}
              >
                <Button
                  color="secondary"
                  onClick={handleZoomOpen}
                  variant="outlined"
                >
                  Приблизить картинку
                </Button>
              </CardActions>
            </CardContent>
          )}
        </CardActionArea>
      </Card>

      <Dialog fullWidth maxWidth="md" open={zoomOpen} onClose={handleZoomClose}>
        <DialogTitle>
          <IconButton onClick={handleZoomClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={props.imgAdress}
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
