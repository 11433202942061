import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

const linkStyle = {
  color: "#ffab91",
  textDecoration: "none",
};

const activeLinkStyle = {
  color: "#bcaaa4",
  textDecoration: "none",
};

export default function Feedback(props) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: props.colorText,
        textAlign: "center",
        borderTop: 1,
        pt: 1,
        pb: 1,
      }}
    >
      <Typography>
        Наш сайт:{" "}
        <Link
          to={"https://kvantorium-norilsk.ru"}
          style={{ color: props.color }}
        >
          https://kvantorium-norilsk.ru
        </Link>
      </Typography>
      <Typography>
        Наш ВК:{" "}
        <NavLink
          to={"https://vk.com/itcube.norilsk"}
          style={{ color: props.color }}
          activeStyle={activeLinkStyle}
        >
          https://vk.com/itcube.norilsk
        </NavLink>
      </Typography>
      <Typography>
        Telegram:{" "}
        <NavLink
          to={"https://t.me/itcubenorilsk"}
          style={{ color: props.color }}
          activeStyle={activeLinkStyle}
        >
          https://t.me/itcubenorilsk
        </NavLink>
      </Typography>{" "}
      <Typography>
        Email:{" "}
        <span style={{ color: props.color }}>info@itcube-norilsk.ru</span>
      </Typography>
      <Typography>
        Тел.:{" "}
        <span style={{ color: props.color }}>
          8(913)-504-33-77,
          <br /> 8(913)-504-33-00
        </span>
      </Typography>
    </Box>
  );
}
