import {
  Container,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Input,
  Button,
  Link,
  Collapse,
  Alert,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import logo from "../images/logo.png";
import axios from "axios";
import Feedback from "./feedback";

export const AuthForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (email && password && isAgree === true) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [email, password, isAgree]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("username", email);
      bodyFormData.append("password", password);
      await axios.post("/api/auth/jwt/login", bodyFormData);
      props.setIsAuth(true);
      const res = await axios.get("/api/user/user-data");
      props.setAuthData({
        fio: res.data.fio,
        score: res.data.score,
        schoolname: res.data.schoolname,
      });
    } catch {
      setOpen(true);
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        minHeight: "100vh",
        height: "100%",
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: { xs: "80%", sm: "75%", md: "35%" },
        padding: { xs: 3, sm: 5, md: 8 },
        marginTop: "-3vh",
      }}
    >
      {" "}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <img
          src={logo}
          alt="Описание изображения"
          style={{
            maxWidth: "140px",
            height: "auto",
            alignSelf: "center",
          }}
        />

        <Typography
          sx={{
            fontSize: "36px",
            textAlign: "center",
            mb: 8,
            mt: 5,
            color: "black",
          }}
        >
          Добро пожаловать!
        </Typography>

        <TextField
          label="Почта"
          variant="standard"
          color="secondary"
          focused
          InputProps={{
            sx: {
              color: "black",
            },
          }}
          onChange={(e) => setEmail(e.target.value)}
        />

        <FormControl
          label="Пароль"
          variant="standard"
          color="secondary"
          focused
          sx={{ mt: 2 }}
          onChange={(e) => setPassword(e.target.value)}
        >
          <InputLabel>Пароль</InputLabel>
          <Input
            inputProps={{
              maxLength: 20,
              sx: {
                color: "black",
              },
            }}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff
                      sx={{
                        color: "black",
                      }}
                    />
                  ) : (
                    <Visibility
                      sx={{
                        color: "black",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Пароль"
          />
        </FormControl>
        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox color="secondary" onChange={() => setIsAgree(!isAgree)} />
          }
          label={
            <Link
              color="secondary"
              href="https://disk.yandex.ru/i/R-F-Fe1m6lJYvg"
              underline="none"
            >
              Согласен на обработку персональных данных*
            </Link>
          }
        />

        {/*<Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          pt: 2,
          pb: 3,
          color: { xs: "white", sm: "black" },
        }}
      >
        У вас нет учетной записи?
        <Link color="secondary" href="#" underline="none">
          {" "}
          <b> Зарегистрироваться</b>
        </Link>
      </Typography>*/}
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mt: 2 }}
          >
            Пользователя с такими данными не существует! <br />
          </Alert>
        </Collapse>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            m: 5,
            mt: 2,
            padding: 1.5,
            fontSize: 18,
            backgroundColor: isActive ? "" : "grey",
          }}
          disabled={!isActive}
          onClick={handleLogin}
        >
          Войти
        </Button>
        {/*<Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            pb: 6,
            color: "black",
            lineHeight: 1.25,
          }}
        >
          <Link color="secondary" href="#" underline="none">
            {" "}
            Политика конфиденциальности
            <br />
          </Link>
          <b> </b> и
          <Link color="secondary" href="#" underline="none">
            {" "}
            <br />
            Условия использования
          </Link>
        </Typography>*/}
      </Box>
      <Feedback color={"#9500ae"} />
    </Container>
  );
};
