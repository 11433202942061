import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import logo from "../images/logoAct.png";
import Feedback from "./feedback";
import axios from "axios";

export default function RightPath(props) {
  const [data, setData] = React.useState([
    { number: 1, school: "Крыло самолета", score: 20 },
  ]);

  // Функция для загрузки данных о топ-школах
  const leadersRefresh = async () => {
    try {
      const res = await axios.get("/api/top/top-schools");
      setData(res.data);
    } catch {
      setData([{ number: 1, school: "Ошибка загрузки данных", score: 0 }]);
    }
  };

  // Вызов функции leadersRefresh при монтировании компонента и установка интервала
  React.useEffect(() => {
    // Загрузка данных при монтировании компонента
    leadersRefresh();
    // Установка интервала для обновления данных каждые 5 мин
    const intervalId = setInterval(leadersRefresh, 120000);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "30%", md: "25%" },
        mt: { xs: 3 },
        justifyContent: "space-between",
        height: { xl: "90vh" },
        pr: { xs: 0, sm: 3, md: 3 },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            borderBottom: 2,
            borderColor: "white",
            pb: 3,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              color: "white",
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <img src={logo} alt="" style={{ height: "55px" }} />
            <Box>
              <Typography sx={{ fontSize: "18px" }}>
                {props.authData ? props.authData.fio : "0"}
              </Typography>
              <Typography sx={{ mb: 2, fontSize: "16px" }}>
                {props.authData ? props.authData.email : "0"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ color: "white", textAlign: "center" }}>
            <Typography>
              Ваша школа: {props.authData ? props.authData.schoolname : "0"}
            </Typography>
            <Typography>
              Ваши баллы: {props.authData ? props.authData.score : "0"}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              mt: 5,
              color: "white",
              fontSize: "20px",
            }}
          >
            <b>Доска лидеров</b>
          </Typography>

          <Box
            sx={{
              mt: 3,
              color: "white",
              mb: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                px: 2,
                borderBottom: "1px solid #fff",
              }}
            >
              <Typography
                sx={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
              >
                №
              </Typography>
              <Typography
                sx={{ flex: 3, textAlign: "center", fontWeight: "bold" }}
              >
                Школа
              </Typography>
              <Typography
                sx={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
              >
                Баллы
              </Typography>
            </Box>

            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                  px: 2,
                  py: 1,
                  borderBottom: "1px solid #fff",
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <Typography sx={{ flex: 1, textAlign: "center" }}>
                  {index + 1}
                </Typography>
                <Typography sx={{ flex: 3, textAlign: "center" }}>
                  {item.schoolname}
                </Typography>
                <Typography sx={{ flex: 1, textAlign: "center" }}>
                  {item.total_score}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Feedback color={"#ffab91"} colorText={"white"} />
    </Box>
  );
}
