import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import {
  CardActionArea,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ZoomIn,
} from "@mui/icons-material";
import { useState } from "react";
import i1 from "../images/2.5.1.png";
import i2 from "../images/2.5.2.png";
import i3 from "../images/2.5.3.png";
import i4 from "../images/2.5.4.png";
import i5 from "../images/2.5.5.png";
import i6 from "../images/2.5.6.png";
import i7 from "../images/2.5.7.png";
import i8 from "../images/2.5.8.png";
import i9 from "../images/2.5.9.png";
import i10 from "../images/2.5.10.png";

export default function ALotOfImages() {
  const [zoomOpen, setZoomOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const columns = isMobile ? 1 : 2;

  const handleZoomOpen = (index) => {
    setCurrentImageIndex(index);
    setZoomOpen(true);
  };

  const handleZoomClose = () => {
    setZoomOpen(false);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? itemData.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === itemData.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <ImageList
      cols={columns}
      sx={{ maxWidth: 500, maxHeight: 450, alignSelf: "center" }}
    >
      {itemData.map((item, index) => (
        <CardActionArea key={item.img} onClick={() => handleZoomOpen(index)}>
          <ImageListItem>
            <ImageListItemBar
              position="top"
              actionIcon={<ZoomIn sx={{ color: "white !important" }} />}
            />
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{ height: "250px" }}
            />
          </ImageListItem>
        </CardActionArea>
      ))}
      <Dialog fullWidth maxWidth="md" open={zoomOpen} onClose={handleZoomClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={handleZoomClose}>
            <Close />
          </IconButton>
          <div>
            <IconButton onClick={handlePrevImage}>
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={handleNextImage}>
              <KeyboardArrowRight />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <img
            src={itemData[currentImageIndex].img}
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </ImageList>
  );
}

const itemData = [
  {
    img: i1,
  },
  {
    img: i2,
  },
  {
    img: i3,
  },
  {
    img: i4,
  },
  {
    img: i5,
  },
  {
    img: i6,
  },
  {
    img: i7,
  },
  {
    img: i8,
  },
  {
    img: i9,
  },
  {
    img: i10,
  },
];
