import { Box, Typography } from "@mui/material";
import Sopostav from "../components/sopostav";
import { First } from "../components/first";
import { Second } from "../components/second";
import Bingo from "../components/bingo";
import i11 from "../images/1.1.jpg";
import i12 from "../images/1.2.jpg";
import i13 from "../images/1.3.jpg";
import i14 from "../images/1.4.jpg";
import i15 from "../images/1.5.jpg";
import i16 from "../images/1.6.jpg";
import i20 from "../images/2.0.png";
import i21 from "../images/2.1.png";
import i22 from "../images/2.2.jpg";
import i23 from "../images/2.3.png";
import i24 from "../images/2.4.png";
import i30 from "../images/3.0.png";

export const tasksData = [
  {
    id: 0,
    taskTitle: "Главная",
    taskType: (
      <Box sx={{ height: "100%", textAlign: "center" }}>
        <Typography sx={{ fontSize: "24px" }}>
          У вас есть возможность поучаствовать в квизе до 14 ноября, 10:00. Вы
          можете ответить на вопросы только один раз, время на размышления – 30
          минут, но не беспокойтесь, этого достаточно для полного прохождения!
          Всего можно заработать 36 баллов. Правильные ответы будут доступны 14
          ноября в 12:00. <br />
          Электронные сертификаты участников мы отправим на школьную электронную
          почту. <br /> <br />
          Готовы проверить свои знания и выйти за грань привычного клика? Удачи
          и приятного участия!
        </Typography>
      </Box>
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 1,
    taskTitle: "Раздел первый: Факт и фактоид ",
    taskText: "Сопоставь следующие понятия и их определения:",
    taskType: <Sopostav taskPost={"/api/tasks/check-task/"} id={1} />,
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 2,
    taskTitle: "Вопрос 1",
    taskType: (
      <First
        taskText={
          "Определи, является ли утверждение фактом или фактоидом: Все бесплатные Wi-Fi сети безопасны, если они требуют пароль для подключения. "
        }
        taskTheme={"Wi-Fi"}
        taskFact={
          'Термин "Wi-Fi" на самом деле является торговой маркой Wi-Fi Alliance. Он является сокращением от "Wireless Fidelity".'
        }
        imgAdress={i11}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={2}
        taskAnswer={""}
      />
    ),
  },
  {
    id: 3,
    taskTitle: "Вопрос 2",
    taskType: (
      <First
        taskText={
          'Определи, является ли утверждение фактом или фактоидом: Каждый раз, когда вы лайкаете или комментируете что-то в соцсетях, это может стать частью вашего "цифрового следа." '
        }
        taskTheme={"Цифровой след"}
        taskFact={
          "- это информация, которую мы оставляем в Интернете при взаимодействии социальных медиа, онлайн-сервисов, платформ, и в целом - при использовании цифровых технологий."
        }
        imgAdress={i12}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={3}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 4,
    taskTitle: "Вопрос 3",
    taskType: (
      <First
        taskText={
          "Определи, является ли утверждение фактом или фактоидом: Большинство фишинговых писем приходят со знакомыми именами в теме, чтобы увеличить шансы на их открытие. "
        }
        taskTheme={"Фишинговые письма"}
        taskFact={
          "2023 году APWG (Anti-Phishing Working Group) зафиксировала почти пять миллионов фишинговых атак, что делает этот год худшим для фишинга за всю историю наблюдений."
        }
        imgAdress={i13}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={4}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 5,
    taskTitle: "Вопрос 4",
    taskType: (
      <First
        taskText={
          "Определи, является ли утверждение фактом или фактоидом: Когда на смартфоне устанавливаешь новое приложение, оно может автоматически начать отслеживать местоположение, даже если доступ к геолокации не был предоставлен. "
        }
        taskTheme={"Отслеживание местоположения"}
        taskFact={
          "Помимо GPS и ГЛОНАСС, технологии отслеживания включают Wi-Fi, сотовые вышки, Bluetooth, RFID и даже камеры для определения местоположения."
        }
        imgAdress={i14}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={5}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 6,
    taskTitle: "Вопрос 5 ",
    taskType: (
      <First
        taskText={
          "Определи, является ли утверждение фактом или фактоидом: Открытие вложения в документе Word не может навредить компьютеру, так как это текстовый файл.  "
        }
        taskTheme={"Word"}
        taskFact={
          "Некоторые версии Word могут иметь встроенные функции безопасности, такие как зашифрованные документы и защита паролем."
        }
        imgAdress={i15}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={6}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 7,
    taskTitle: "Вопрос 6",
    taskType: (
      <First
        taskText={
          "Определи, является ли утверждение фактом или фактоидом: Ваш голос, записанный на умное устройство, можно использовать для восстановления забытых паролей на банковских аккаунтах."
        }
        taskTheme={"Сохранность данных"}
        taskFact={
          "Используйте надежные уникальные пароли. Включите двухфакторную аутентификацию, где это возможно. Следите за обновлениями ПО и антивирусов"
        }
        imgAdress={i16}
        ans={["Факт", "Фактоид"]}
        taskPost={"/api/tasks/check-task/"}
        id={7}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 8,
    taskTitle: "Раздел Второй: Это фишинг!",
    taskType: (
      <First
        taskText={
          "Какой из вариантов наиболее точно описывает, что такое фишинг?"
        }
        imgAdress={i20}
        ans={[
          "Вид интернет-мошенничества",
          "Способ защиты данных на сервере от взлома",
          "Программа для проверки безопасности веб-сайтов",
        ]}
        taskTheme={"Осторожность с приложениями"}
        taskFact={
          "Изучайте разрешения, которые запрашивают приложения, и избегайте тех, которые требуют слишком много доступа."
        }
        taskPost={"/api/tasks/check-task/"}
        id={8}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 9,
    taskTitle: "Вопрос 1",
    taskType: (
      <Second
        tt={2}
        imgAdress={i21}
        checkboxes={[
          { name: 2, label: "Неправильный адрес страницы" },
          { name: 3, label: "Небезопасное соединение с сайтом" },
          { name: 4, label: "Срочный призыв к действию " },
          { name: 5, label: "Просьба ввести личные данные" },
          { name: 6, label: "Ссылки на сторонние источники" },
          { name: 7, label: "Грамматические ошибки" },
          { name: 8, label: "Запросы о конфиденциальной информации" },
          { name: 9, label: "Необычные элементы дизайна" },
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={9}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 10,
    taskTitle: "Вопрос 2",
    taskType: (
      <Second
        tt={2}
        imgAdress={i22}
        checkboxes={[
          { name: 2, label: "Неизвестный номер" },
          { name: 3, label: "Недавно зарегистрированный аккаунт" },
          { name: 4, label: "Ссылки на сторонние источники " },
          { name: 5, label: "Срочный призыв к действию " },
          { name: 6, label: "Просьба отправить личные данныенние" },
          { name: 7, label: "Грамматические ошибки" },
          { name: 8, label: "Запросы о конфиденциальной информации" },
          { name: 9, label: "Отсутствие персонализации " },
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={10}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 11,
    taskTitle: "Вопрос 3",
    taskType: (
      <Second
        tt={2}
        imgAdress={i23}
        checkboxes={[
          { name: 2, label: "Отсутствие темы" },
          { name: 3, label: "Ссылки на сторонние источники" },
          { name: 4, label: "Создание чувства срочности действия" },
          { name: 5, label: "Отсутствие персонализации " },
          { name: 6, label: "Прикрепленные файлы" },
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={11}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 12,
    taskTitle: "Вопрос 4",
    taskType: (
      <Second
        tt={2}
        imgAdress={i24}
        checkboxes={[
          { name: 2, label: "Ограничение по времени" },
          { name: 3, label: "Просьба ввести личные данные" },
          { name: 4, label: "Ссылки на сторонние источники " },
          { name: 5, label: "Грамматические ошибки" },
          { name: 6, label: "Запросы о конфиденциальной информации" },
          { name: 7, label: "Обилие всплывающих окон" },
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={12}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 13,
    taskTitle: "Вопрос 5",
    taskType: (
      <Second
        tt={2}
        checkboxes={[
          { name: 2, label: "Мало информации о человеке" },
          { name: 3, label: "Странная или пустая лента" },
          { name: 4, label: "Непонятные фото профиля " },
          { name: 5, label: "Накрутка лайков" },
          { name: 6, label: "Фейковые страницы в друзьях" },
          { name: 7, label: "Подозрительные сообщения" },
          { name: 8, label: "Новая страница" },
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={13}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 14,
    taskTitle: "Раздел третий: Бинго безопасности",
    taskType: (
      <First
        tt={2}
        gg={6}
        taskText={
          "Что представляет собой информационная безопасность и какие цели она преследует?"
        }
        imgAdress={i30}
        ans={[
          "Защита от компьютерных вирусов",
          "Защита информации, данных и систем от несанкционированного доступа, изменения или уничтожения",
          "Мониторинг интернет-трафика на серверах",
          "Создание резервных копий информации",
        ]}
        taskPost={"/api/tasks/check-task/"}
        id={14}
      />
    ),
    taskPost: "",
    taskAnswer: "",
  },
  {
    id: 15,
    taskTitle: "БИНГО",
    taskPost: "",
    taskType: <Bingo />,
    taskAnswer: "",
  },
];
