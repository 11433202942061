import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { bingoData } from "../static/bingoData";
import axios from "axios";
import { Close } from "@mui/icons-material";

export default function Bingo() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl")); // Проверка на мобильное устройство

  const [state, setState] = React.useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  });

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.checked,
    }));
  };

  const handleButtonClick = async () => {
    const checkedValues = Object.values(state)
      .map((value) => (value ? "1" : "0"))
      .join("");
    try {
      const response = await axios.post("/api/tasks/check-finish-task", null, {
        params: { answer: '"' + checkedValues + '"' },
      });
      setModalOpen(true);
    } catch (error) {
      alert(typeof checkedValues);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: "20px", mb: 2, textAlign: "center" }}>
        Давайте посмотрим, насколько хорошо вы соблюдаете эти принципы в своей
        повседневной жизни.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {bingoData.map((data, index) => (
          <Paper
            key={index}
            elevation={4}
            sx={{
              backgroundColor: data.color,
              color: "white",
              textAlign: "center",
              p: isMobile ? 2 : 1,
              width: isMobile ? "calc(50% - 8px)" : "calc(20% - 8px)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              label={data.text}
              labelPlacement="bottom"
              control={
                <Checkbox
                  checked={state[data.id]}
                  onChange={handleChange(data.id)}
                  sx={{ color: "white !important" }}
                />
              }
            />
          </Paper>
        ))}
      </Box>
      <Collapse in={modalOpen}>
        <Alert
          severity={"success"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mt: 2 }}
        >
          Успешно отправлено
          <br />
        </Alert>
      </Collapse>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleButtonClick}
        sx={{ mt: 2, width: "50%", alignSelf: "center" }}
      >
        Отправить ответ
      </Button>
    </Box>
  );
}
