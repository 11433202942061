import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import { ImageCard } from "./imageCard";
import ALotOfImages from "./aLotOfImages";
import axios from "axios";
import { Close } from "@mui/icons-material";

export const Second = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(true);
  const [ans, setAns] = useState("");

  const [state, setState] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });

  const [showAdditionalCheckboxes, setShowAdditionalCheckboxes] =
    useState(false);

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.checked,
    }));

    if (
      (name === 1 && event.target.checked) ||
      (name === 1 && !event.target.checked)
    ) {
      setShowAdditionalCheckboxes(!showAdditionalCheckboxes);
      if (showAdditionalCheckboxes === false) {
        setState({
          0: false,
          1: !state[1],
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
        });
      }
      if (showAdditionalCheckboxes === true) {
        setState({
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
        });
      }
    }
    if (
      (name === 0 && event.target.checked) ||
      (name === 0 && !event.target.checked)
    ) {
      setShowAdditionalCheckboxes(false);
      if (showAdditionalCheckboxes === true) {
        setState({
          0: !state[0],
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
        });
      }
    }
  };

  // Используем useEffect для проверки, есть ли хотя бы одна галочка
  useEffect(() => {
    const hasChecked = Object.values(state).some((value) => value);
    setIsDisabled(!hasChecked);
    const answer = Object.values(state)
      .map((value) => (value ? "1" : "0"))
      .join("");
    setAns(answer);
  }, [state]);

  return (
    <Box sx={{ height: "100%" }}>
      <Typography sx={{ fontSize: "20px" }}>
        Изучите сайт на картинке: опасен он или нет? Если опасен, выберите
        пункты, объясняющие - почему
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: { sm: "space-around", xl: "space-between" },
          flexDirection: { xs: "column", sm: "column", xl: "row" },
        }}
      >
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={state[0]} onChange={handleChange(0)} />
              }
              label={<span style={{ fontSize: "20px" }}>Безопасен</span>}
            />
            <FormControlLabel
              control={
                <Checkbox checked={state[1]} onChange={handleChange(1)} />
              }
              label={<span style={{ fontSize: "20px" }}>Опасен</span>}
            />

            {showAdditionalCheckboxes && (
              <>
                {props.checkboxes.map((checkbox) => (
                  <FormControlLabel
                    key={checkbox.name}
                    control={
                      <Checkbox
                        checked={state[checkbox.name]}
                        onChange={handleChange(checkbox.name)}
                        name={checkbox.name}
                      />
                    }
                    label={
                      <span style={{ fontSize: "20px" }}>{checkbox.label}</span>
                    }
                  />
                ))}
              </>
            )}
          </FormGroup>
          <FormHelperText>
            <span style={{ fontSize: "16px", textWrap: "wrap" }}>
              В данном задании может быть как один ответ, так и несколько
            </span>
          </FormHelperText>
          <Collapse in={modalOpen}>
            <Alert
              severity={
                modalData ===
                "Серверные шоколадки, попробуйте еще раз отправить ответ"
                  ? "error"
                  : "success"
              }
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {modalData}
              <br />
            </Alert>
          </Collapse>
          <Button
            disabled={isDisabled}
            variant="contained"
            color="secondary"
            sx={{ mt: 4, mb: 4 }}
            onClick={async () => {
              const answer = Object.values(state)
                .map((value) => (value ? "1" : "0"))
                .join("");
              setAns(answer);
              try {
                const requestBody = {
                  task_id: props.id,
                  answer: ans + "",
                };
                const response = await axios.post(
                  "/api/tasks/check-task",
                  requestBody
                );
                setModalData(response.data.message);
                setModalOpen(true);
              } catch (error) {
                setModalData(
                  "Серверные шоколадки, попробуйте еще раз отправить ответ"
                );
                setModalOpen(true);
              }
            }}
          >
            Отправить ответ
          </Button>
        </FormControl>

        {props.id === 13 ? (
          <ALotOfImages />
        ) : (
          <ImageCard imgAdress={props.imgAdress} tt={props.tt} />
        )}
      </Box>
    </Box>
  );
};
