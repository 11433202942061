import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { tasksData } from "../static/tasks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import logo from "../images/logoAct.png";
import RightPath from "./rightPath";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      sx={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, minHeight: "85%", pb: 0, height: "auto" }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MainComponent(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNext = () => {
    setValue((prevValue) => (prevValue + 1) % tasksData.length);
  };
  const handleBack = () => {
    setValue((prevValue) => (prevValue - 1) % tasksData.length);
  };

  const [open, setOpen] = React.useState(false);
  const [answer, setAnswer] = React.useState(false);

  /*const handleClickOpen = async () => {
    setOpen(true); // Открыть диалог
    try {
      const response = await axios.get("/api/Correct-answers/", {
        task_id: 1,
      });
      console.log(response.data);
    } catch (error) {
      setAnswer("Серверные шоколадки, попробуйте еще раз");
    }
  };*/
  const handleClose = () => {
    setOpen(false); // Закрыть диалог
  };

  const theme = createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // Установите цвет стрелок
          },
        },
      },
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        height: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: { xs: "100%", sm: "25%" },
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            alignItems: "center",
            minHeight: { xs: "20vh", sm: "70vh" },
            /*position: { xs: "fixed", sm: "relative", xl: "relative" },
            mt: { xs: -4 },*/
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              p: 1,
            }}
          >
            <img
              src={logo}
              style={{
                maxWidth: "75px",
                height: "auto",
                alignSelf: "center",
                marginBottom: 15,
              }}
            />
            <Typography sx={{ mb: 5, color: "white" }}>
              {" "}
              <b>IT-КУБ. Норильск</b>
            </Typography>
          </Box>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            indicatorColor="secondary"
            textColor="inherit"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              height: { xs: "20vh", sm: "65vh" },
            }}
          >
            {tasksData.map((task) => (
              <Tab
                sx={{
                  color: "white",
                  fontSize: 20,
                  textTransform: "capitalize",
                }}
                label={task.taskTitle}
              />
            ))}
          </Tabs>
        </Box>
        <Typography
          sx={{
            textAlign: "end",
            pr: 2,
            color: "white",
            display: { sm: "none", xl: "none" },
          }}
        >
          {" "}
          Эту штучку можно прокручивать &#8613;
        </Typography>
      </ThemeProvider>

      {tasksData.map((task, idx) => (
        <CustomTabPanel key={idx} value={value} index={idx}>
          <Box
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              padding: { xs: 2, sm: 5, md: 5 },
              pb: 0,
              borderRadius: "10px 10px 0px 0px",
              backgroundColor: "white",
              border: 10,
              borderStyle: "solid",
              borderColor: "white",
              boxShadow: 3,
              minHeight: { xs: "", sm: "80vh", xl: "80vh" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "32px", xl: "60px" },
                textAlign: "center",
                mb: 3,
              }}
            >
              {task.taskTitle === "Главная" ? (
                <>
                  Добро пожаловать в квиз <br />
                  "За гранью клика"!
                </>
              ) : (
                task.taskTitle
              )}
            </Typography>
            {task.taskType}

            <Box
              sx={{
                flex: "1",
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: { xs: 2, sm: 5 },
                mt: 1,
              }}
            >
              <Button
                onClick={handleBack}
                color="secondary"
                variant="contained"
                sx={{
                  display: task.taskTitle === "Главная" ? " none" : "block",
                }}
              >
                &#9756;К предыдущему заданию
              </Button>
              <Button
                onClick={handleNext}
                color="secondary"
                variant="contained"
                sx={{
                  display: task.taskTitle === "БИНГО" ? " none" : "block",
                }}
              >
                К следующему заданию &#9758;
              </Button>
            </Box>

            <Button
              color="secondary"
              variant="outlined"
              sx={{
                mt: 2,
                display:
                  task.taskTitle === "Главная" || task.taskTitle === "БИНГО"
                    ? "none"
                    : "block", // Скрывать кнопку, если это главная задача
              }}
              onClick={async () => {
                setOpen(true); // Открыть диалог
                try {
                  const response = await axios.get("/api/Correct-answers", {
                    params: {
                      task_id: task.id,
                    },
                  });
                  setAnswer(response.data.status);
                } catch (error) {
                  setAnswer("Серверные шоколадки, попробуйте еще раз");
                }
              }}
            >
              Узнать ответ на задание
            </Button>

            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <Box
                  sx={{
                    fontSize: "24px",
                    textWrap: "wrap",
                    p: 2,
                    textAlign: "center",
                    maxWidth: "400px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <Typography variant="h4">{task.taskTitle}</Typography>
                  <Typography variant="h5">{answer}</Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Закрыть
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </CustomTabPanel>
      ))}
      <RightPath authData={props.authData} />
    </Box>
  );
}
