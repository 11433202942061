export const bingoData = [
  {
    id: 0,
    text: "Я всегда проверяю адрес сайта перед тем, как вводить свои данные.",
    color: "rgb(155, 80, 80, 0.9)",
  },
  {
    id: 1,
    text: "Я меняю свой пароль на всех аккаунтах раз в три месяца.",
    color: "rgb(155, 80, 80, 0.7)",
  },
  {
    id: 2,
    text: "Я использую двухфакторную аутентификацию для защиты своих аккаунтов.",
    color: " rgba(155, 80, 80, 0.6)",
  },
  {
    id: 3,
    text: "Я проверяю настройки конфиденциальности в соцсетях.",
    color: "rgba(50, 19, 79, 0.5)",
  },
  {
    id: 4,
    text: "Я всегда читаю отзывы перед установкой нового приложения на телефон.",
    color: "rgb(155, 80, 80, 0.7)",
  },
  {
    id: 5,
    text: "Я использую разные пароли для разных сайтов.",
    color: " rgba(155, 80, 80, 0.6)",
  },
  {
    id: 6,
    text: "Я сразу блокирую подозрительные профили, которые пишут мне в соцсетях.",
    color: "rgba(50, 19, 79, 0.5)",
  },
  {
    id: 7,
    text: "Я рассказываю взрослым, если получаю подозрительные сообщения.",
    color: "rgba(50, 19, 79, 0.6)",
  },
  {
    id: 8,
    text: "Я всегда использую антивирус на своих устройствах.",
    color: " rgba(155, 80, 80, 0.6)",
  },
  {
    id: 9,
    text: "Я регулярно делаю резервное копирование важных данных.",
    color: "rgba(50, 19, 79, 0.5)",
  },
  {
    id: 10,
    text: "Я проверяю, кто мои друзья в соцсетях, и не добавляю незнакомых людей.",
    color: "rgba(50, 19, 79, 0.6)",
  },
  {
    id: 11,
    text: "Я обсуждаю вопросы информационной безопасности с друзьями и родителями.",
    color: "rgba(50, 19, 79, 0.8)",
  },
];
