import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import "./admin.css"; // Подключаем CSS для стилей

const Admin = () => {
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = async () => {
    try {
      const response = await axios.get("/api/Adminka");
      setData(response.data);
      setIsActive(true);
    } catch (e) {
      alert("Куда это мы лезем??");
      window.location.replace("/");
    }
  };

  const columns = [
    { field: "email", headerName: "Email", width: 100 },
    { field: "fio", headerName: "ФИО", width: 200 },
    { field: "birthday", headerName: "Дата рождения", width: 150 },
    { field: "schoolname", headerName: "Школа", width: 200 },
    { field: "schoolclass", headerName: "Класс", width: 100 },
    { field: "mentor", headerName: "Наставник", width: 200 },
    { field: "task1", headerName: "Задача 1", width: 100 },
    { field: "task2", headerName: "Задача 2", width: 100 },
    { field: "task3", headerName: "Задача 3", width: 100 },
    { field: "task4", headerName: "Задача 4", width: 100 },
    { field: "task5", headerName: "Задача 5", width: 100 },
    { field: "task6", headerName: "Задача 6", width: 100 },
    { field: "task7", headerName: "Задача 7", width: 100 },
    { field: "task8", headerName: "Задача 8", width: 100 },
    { field: "task9", headerName: "Задача 9", width: 100 },
    { field: "task10", headerName: "Задача 10", width: 100 },
    { field: "task11", headerName: "Задача 11", width: 100 },
    { field: "task12", headerName: "Задача 12", width: 100 },
    { field: "task13", headerName: "Задача 13", width: 100 },
    { field: "task14", headerName: "Задача 14", width: 100 },
    { field: "task15_1", headerName: "Задача 15.1", width: 50 },
    { field: "task15_2", headerName: "Задача 15.2", width: 50 },
    { field: "task15_3", headerName: "Задача 15.3", width: 50 },
    { field: "task15_4", headerName: "Задача 15.4", width: 50 },
    { field: "task15_5", headerName: "Задача 15.5", width: 50 },
    { field: "task15_6", headerName: "Задача 15.6", width: 50 },
    { field: "task15_7", headerName: "Задача 15.7", width: 50 },
    { field: "task15_8", headerName: "Задача 15.8", width: 50 },
    { field: "task15_9", headerName: "Задача 15.9", width: 50 },
    { field: "task15_10", headerName: "Задача 15.10", width: 50 },
    { field: "task15_11", headerName: "Задача 15.11", width: 50 },
    { field: "task15_12", headerName: "Задача 15.12", width: 50 },
    { field: "score", headerName: "Баллы", width: 100 },
  ].map((col) => ({
    ...col,
    headerClassName: selectedColumn === col.field ? "selected-column" : "",
    cellClassName: (params) =>
      selectedColumn === params.field ? "selected-cell" : "",
    sortable: true,
  }));

  const handleColumnHeaderClick = (field) => {
    setSelectedColumn(field === selectedColumn ? null : field);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection.rowIds);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
      <Button
        color="error"
        variant="contained"
        disabled={!isActive}
        sx={{ width: "50%", mb: 3, alignSelf: "center" }}
        onClick={async () => {
          try {
            await axios.post("/api/import-users");
          } catch {
            alert("не импортируется");
          }
        }}
      >
        Жмай только один раз!
      </Button>
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[1000]}
        getRowId={(row) => row.email}
        disableSelectionOnClick
        sortingOrder={["asc", "desc"]}
        checkboxSelection
        density="compact"
        disableRowSelectionOnClick
        sx={{ backgroundColor: "lightgray" }}
        onColumnHeaderClick={(params) => handleColumnHeaderClick(params.field)}
        onSelectionModelChange={handleSelectionChange}
        selectionModel={selectedRows} // Управление выбранными строками
      />
    </Box>
  );
};

export default Admin;
