import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/roboto/300.css";
import { AuthForm } from "./components/auth";
import { useCookies } from "react-cookie";
import * as React from "react";
import MainComponent from "./components/mainComponent";
import "./App.css";
import axios from "axios";
import Admin from "./components/admin";

function App() {
  const [cookies] = useCookies(["aboba"]);
  const [isAuth, setIsAuth] = React.useState(false);
  const [authData, setAuthData] = React.useState();

  // Функция для получения данных пользователя
  const refreshData = async () => {
    try {
      const res = await axios.get("/api/user/user-data");
      setAuthData({
        fio: res.data.fio,
        score: res.data.score,
        schoolname: res.data.schoolname,
        email: res.data.email,
      });
    } catch {
      console.error("Ошибка при загрузке данных пользователя");
    }
  };

  // Вызов refreshData при монтировании и каждые 2 минуты
  React.useEffect(() => {
    refreshData(); // Загрузка данных при монтировании

    const intervalId = setInterval(refreshData, 120000); // Интервал 2 минуты

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании
  }, []);

  // Проверка на наличие cookie для аутентификации
  React.useEffect(() => {
    if (cookies.aboba === undefined) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, [cookies]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: isAuth ? (
        <MainComponent
          isAuth={isAuth}
          authData={authData}
          setAuthData={setAuthData}
        />
      ) : (
        <AuthForm setIsAuth={setIsAuth} setAuthData={setAuthData} />
      ),
    },
    {
      path: "/*",
      element: isAuth ? (
        <MainComponent
          isAuth={isAuth}
          authData={authData}
          setAuthData={setAuthData}
        />
      ) : (
        <AuthForm setIsAuth={setIsAuth} setAuthData={setAuthData} />
      ),
    },
    {
      path: "adminSuperSecretPanel",
      element: <Admin setAuthData={setAuthData} authData={authData} />,
    },
  ]);

  return (
    <div id="main">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
