import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";

const questions = [
  {
    question:
      "Явление или событие, подкреплено надежными свидетельствами, истина.",
    answers: ["Факт", "Фактоид", "Фактчекинг"],
  },
  {
    question:
      "Проверка фактических данных для установления их достоверности и корректности.",
    answers: ["Факт", "Фактоид", "Фактчекинг"],
  },
  {
    question:
      "Сомнительные, ложные данные, принимаемые за действительную информацию, ложь.",
    answers: ["Факт", "Фактоид", "Фактчекинг"],
  },
];

const Sopostav = (props) => {
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(questions.length).fill("")
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(true);
  const [ans, setAns] = useState("");

  const [answer, setAnswer] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false); // Закрыть диалог
  };

  // Проверка, даны ли ответы на все вопросы
  useEffect(() => {
    const allAnswered = selectedAnswers.every((answer) => answer !== "");
    setIsDisabled(!allAnswered);
  }, [selectedAnswers]);

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = answer;
    setSelectedAnswers(newAnswers);
  };

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
      {questions.map((q, index) => (
        <FormControl key={index} color="default" sx={{ mb: 2 }}>
          <Typography>
            <span style={{ fontSize: "20px" }}>{q.question}</span>
          </Typography>
          <RadioGroup
            value={selectedAnswers[index]}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
            sx={{ ml: 10 }}
          >
            {q.answers.map((answer, answerIndex) => (
              <FormControlLabel
                key={answerIndex}
                value={answer}
                control={
                  <Radio
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    color="secondary"
                  />
                }
                label={
                  <span style={{ fontSize: "20px", marginLeft: "10px" }}>
                    {answer}
                  </span>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      ))}
      <Collapse in={modalOpen}>
        <Alert
          severity={
            modalData ===
            "Серверные шоколадки, попробуйте еще раз отправить ответ"
              ? "error"
              : "success"
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mt: 2 }}
        >
          {modalData}
          <br />
        </Alert>
      </Collapse>
      <Button
        disabled={isDisabled}
        variant="contained"
        color="secondary"
        sx={{ mt: 2, width: "50%", textWrap: "wrap", alignSelf: "center" }}
        onClick={async () => {
          setAns(selectedAnswers.join(""));
          try {
            const requestBody = {
              task_id: props.id,
              answer: selectedAnswers.join(""),
            };
            const response = await axios.post(
              "/api/tasks/check-task",
              requestBody
            );
            setModalData(response.data.message);
            setModalOpen(true);
            setOpen(true); // Открыть диалог
            try {
              const response = await axios.get("/api/Correct-answers", {
                params: {
                  task_id: 1,
                },
              });
              setAnswer(response.data.status);
            } catch (error) {
              setAnswer("Серверные шоколадки, попробуйте еще раз");
            }
          } catch (error) {
            setModalData(
              "Серверные шоколадки, попробуйте еще раз отправить ответ"
            );
            setModalOpen(true);
          }
        }}
      >
        Отправить ответ
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              fontSize: "24px",
              textWrap: "wrap",
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography variant="h4">Ответ на задание: </Typography>
            <Typography variant="h5">{answer}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sopostav;
